.match-card {
    background: white;
    /* Default background */

    padding: 0;
    border-width: 1px;
    border-style: solid;
    border-color: #cecece;
    border-radius: 0 !important;
    width: 34.42rem;
    margin-right: 0.6rem;
    margin-bottom: 0.6rem;
}

.header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f3f3f3;
    padding: 0.5rem;
    margin: 0;
    border-width: 1px;
    border-style: solid;
    border-color: #cecece;
    border-radius: 0 !important;
    min-height: 50px;
    border-width: 0px;
    border-bottom-width: 1px;
}

.header-title {
    font-weight: bold;
}

.sets-container {
    display: flex;
    flex-direction: row;
}

.set-box {
    flex: 1;
    display: flex;
    height: 100px;
    width: 50px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #cecece;
    border-width: 0;
    border-radius: 0 !important;
    border-left-width: 1px;
}

.footer-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f3f3f3;
    margin: 0;
    padding: 0.5rem;
    min-height: 50px;
    border-width: 0px;
    border-top-width: 1px;
    border-style: solid;
    border-color: #cecece;
    border-radius: 0 !important;
}

.footer-time,
.footer-status {
    font-weight: bold;
}