.action-button-for-list {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px;
  width: 40px;
  height: 40px;
}

.action-button-for-list:hover {
  opacity: 0.4;
}