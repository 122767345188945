.match-card-line {
  height: 1px;
  background-color: #C2DDFE;
  position: absolute;
  top: 54px;
  width: 100%;
}

.match-card-winner-icon {
  width: 15px;
  margin-bottom: 5px;
}
